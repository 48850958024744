import "./Home.css";
import m30 from "../Data/M-30p.jpg"


const Home = () => {
  return (
    <div>
      <div>
        <div className="left-column align-content-center d-flex justify-content-center">
          <img className="image" src={m30} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
