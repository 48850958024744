import "../Components/Header.css";
import { useState, useEffect } from "react";
/* eslint-disable jsx-a11y/anchor-is-valid */

const Header = () => {
  const [activeLink, setActiveLink] = useState("home");

  const handleLinkClick = (linkId, sectionId) => {
    setActiveLink(linkId);
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const navHeader = document.getElementById("navHeader");
    const dropdown = document.getElementById("dropdown");
    const mq = window.matchMedia("(max-width: 767px)");

    const toggleNav = (mq) => {
      if (mq.matches) {
        navHeader.style.display = "none";
        dropdown.style.display = "block";
      } else {
        navHeader.style.display = "flex";
        dropdown.style.display = "none";
      }
    };

    toggleNav(mq);

    mq.addEventListener("change", toggleNav);

    return () => {
      mq.removeEventListener("change", toggleNav);
    };
  }, []);

  return (
    <header>
      <div className="container-fluid container">
        <div id="navItems" className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex align-items-center c-name">
            <button className="btn border-0">ÖFLUN</button>
          </div>

          <div className="col-lg-8 col-md-8 col-sm-8 col-8 d-flex align-items-center justify-content-end">
            <nav id="navHeader" className="d-none d-md-block">
              <a
                className={activeLink === "home" ? "active" : ""}
                onClick={() => handleLinkClick("home", "home-section")}
              >
                Heim
              </a>

              <a
                className={activeLink === "powerbi" ? "active" : ""}
                onClick={() => handleLinkClick("powerbi", "powerbi-section")}
              >
                Mælaborð
              </a>

              <a
                className={activeLink === "contact" ? "active" : ""}
                onClick={() => handleLinkClick("contact", "contact-section")}
              >
                Hafa samband
              </a>
            </nav>
            <div id="dropdown" className="dropdown">
              <button
                className="btn dropdown-toggle bg-transparent"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{border:"none", backgroundImage: "none" }}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
              <path  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item"   onClick={() => handleLinkClick("home", "home-section")} >
                    Heim
                  </a>
                </li>
                <li>
                  <a className="dropdown-item"  onClick={() => handleLinkClick("powerbi", "powerbi-section")} >
                    Mælaborð
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => handleLinkClick("contact", "contact-section")}>
                    Hafa samband
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
