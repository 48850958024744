import "./App.css";
import Contact from "./Components/Contact";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Project from "./Components/HomeChildComponent/Project";
import Footer from "./Components/Footer";
import PowerBI from "./Components/PowerBI";

function App() {
  return (
    <div style={{ height: "100vh" }}>
      <div className="App App-header">
        <Header />
      </div>
      <div>
        <Home />
        <div id="home-section">
          <Project />
        </div>
        <hr style={{ margin:"50px" }} />
        <div id="powerbi-section">
          <PowerBI />
        </div>
        <hr style={{ margin: "50px" }} />
        <div id="contact-section">
          <Contact />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
