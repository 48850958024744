import { useState, useEffect } from "react";
import "./PowerBI.css";
import gas from "../Data/gas.png";
import earth from "../Data/earth.jpeg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const PowerBI = () => {

  

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEarth, setShowEarth] = useState(false);
  const handleCloseEarth = () => setShowEarth(false);
  const handleShowEarth = () => setShowEarth(true);




  const [error, setError] = useState(null);

  useEffect(() => {
    window.addEventListener("unhandledrejection", (event) => {
      setError(event.reason);
      
    });
    return () => {
      window.removeEventListener("unhandledrejection", (event) => {
        setError(event.reason);
  
      });
    };
    
  }, []);


  console.clear();

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h4 style={{ textAlign: "center", marginTop: "20px" }}>Mælaborð</h4>
        <div>Veldu mælaborð til að skoða</div>
        <div className="cardcontainer">
          <div className="row" style={{ width: "60%" }}>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">Eldsneytisverð</div>
                <img
                  style={{ maxHeight: "175px", minHeight: "175px" }}
                  className="card-img-top"
                  src={gas}
                  alt="gasprices"
                />
                <div className="card-body">
                  <button className="btn btn-outline-dark" onClick={handleShow}>
                    Skoða
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="card">
                <div className="card-header">Jarðskjálftamælingar</div>
                <img
                  style={{ maxHeight: "175px", minHeight: "175px" }}
                  src={earth}
                  alt="earthquake"
                />
                <div className="card-body">
                  <button
                    className="btn btn-outline-dark"
                    onClick={handleShowEarth}
                  >
                    Skoða
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="xl" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eldsneytisverð</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="d-flex align-items-center justify-content-center">
            {error ? (
              <div>Something went wrong: {error.message}</div>
            ) : (
              <iframe
                className="custom-modal"
                title="Elsneytisverd"
                src="https://app.powerbi.com/view?r=eyJrIjoiYWE1YmE5YTMtYzU1My00ODczLWJmMzQtZTBmNDU0NWFlMTM1IiwidCI6IjE3NGEzMzJiLTZlZTgtNDM3MC1iZmI0LWIwODRkYjE5NzIyOCIsImMiOjh9"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Loka
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" centered show={showEarth} onHide={handleCloseEarth}>
        <Modal.Header closeButton>
          <Modal.Title>Jarðskjálftamælingar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="d-flex align-items-center justify-content-center">
            {error ? (
              <div>Something went wrong: {error.message}</div>
            ) : (
              <iframe
                className="custom-modal"
                title="Jarðskjálftamælingar"
                src="https://app.powerbi.com/view?r=eyJrIjoiZmVkMzA3NTgtMzEwZS00OTk5LWFlYmEtMzhjN2JkMjVhZDdkIiwidCI6IjE3NGEzMzJiLTZlZTgtNDM3MC1iZmI0LWIwODRkYjE5NzIyOCIsImMiOjh9"
                frameBorder="0"
                style={{ border: "none" }}
                allowFullScreen={true}
              ></iframe>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEarth}>
            Loka
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PowerBI;
