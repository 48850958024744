import { Card } from "react-bootstrap";
import "../HomeChildComponent/Project.css";

const Project = () => {
  return (
    <div className="bodyP" style={{ marginTop: "20px" }}>
      <div className="c">
        <h4 className="d-flex justify-content-center align-content-center text-center company-content pt-4">
          VELKOMIN
        </h4>
        <div
          style={{
            textAlign: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          Öflun ehf er ráðgjafar- og markaðsfyrirtæki sem sinnir sölu- og
          fjáröflunarverkefnum fyrir meðal annars félög og félagasamtök.
        </div>
        <div
          style={{
            textAlign: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          Í nærri 30 ár hefur Öflun ehf skipulagt og séð um úthringi verkefni af
          ýmsu tagi, eins og sölu á auglýsingum í blöð og tímarit, hvort sem um
          er að ræða útgáfu á pappír eða vef.
        </div>
      </div>
      <div
        style={{ marginLeft: "30px", marginRight: "30px", marginTop: "60px" }}
      >
        <div className="row">
          <div className="col-sm-4 ">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="service1"></div>
            </div>
            <Card>
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>
                  Úthringingar
                </Card.Title>
                <hr />
                <Card.Text>
                  Hjá Öflun ehf er boðið upp á framúrskarandi þjónustu öflugra
                  sölumanna með mikla reynslu, hvort sem um er að ræða sölu á
                  auglýsingum, sölu á vöru, öflun styrktaraðila eða beinar
                  fjársafnanir.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="service2"></div>
            </div>
            <Card>
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>
                  Markhópar
                </Card.Title>
                <hr />
                <Card.Text>
                  Við vinnum markhópa, bæði yfir einstaklinga og fyrirtæki.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="service3"></div>
            </div>
            <Card>
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>
                  Gagnagreining og ráðgjöf
                </Card.Title>
                <hr />
                <Card.Text>
                  Við greinum niðurstöður átaksverkefna og skoðum hver árangur
                  var eftir hinum ýmsu breytum. Niðurstöður eru teknar saman í
                  skýrsluformi og birtar í Microsoft Power BI. Með öflugri
                  gagnagreininginu er hægt að auka hagræði og minnka kostnað við
                  framkvæmd fjáröflunarverkefna.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
