import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-content container-fluid">
      <div className="">
        <div className="d-flex align-content-center justify-content-center">
          Copyright &copy; {new Date().getFullYear()} Öflun ehf - oflun@oflun.is
        </div>
      </div>
    </div>
  );
};

export default Footer;
