import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Col } from "react-bootstrap";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_9jqqoh6",
        "template_tbnfvs7",
        form.current,
        "jXN58dhYF9fEZ_0mX"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setEmail("");
    setMessage("");
    setName("");
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div
        style={{ marginTop: "-40px", marginRight:"20px", marginLeft:"20px" }}
        className="Form d-flex justify-content-center"
      >
        <Col xs={12} sm={8} md={6} lg={4} className="my-5">
          <h4
            style={{
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Hafa samband
          </h4>
          <div className="form-group" style={{ marginBottom: "10px" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Nafn"
              name="user_name"
              required
              value={name}
              onChange={handleChangeName}
            />
          </div>
          <div className="form-group" style={{ marginBottom: "10px" }}>
            <input
              type="email"
              className="form-control"
              placeholder="Netfang"
              name="user_email"
              required
              value={email}
              onChange={handleChangeEmail}
            />
          </div>
          <div className="form-group" style={{ marginBottom: "10px" }}>
            <textarea
              className="form-control"
              id="content"
              rows="3"
              placeholder="Skilaboð"
              name="message"
              required
              value={message}
              onChange={handleChangeMessage}
            />
          </div>

          <input
            style={{
              width: "100%",
            }}
            className="btn btn-outline-dark"
            type="submit"
            value="Send"
          />
        </Col>
      </div>
    </form>
  );
};

export default Contact;
